<template>
  <v-footer class="py-4" color="dark" height="auto">
    <v-container class="mx-auto">
      <v-row class="align-center">
        <div>
          <v-hover v-slot="{ hover }" v-for="(item, i) in items" :key="i">
            <base-btn
              :href="item.href"
              class="ml-0 mr-3"
              :color="hover ? 'accent' : '#7b796f'"
              square
              target="_blank"
            >
              <v-icon v-text="item.icon" />
            </base-btn>
          </v-hover>
        </div>

        <v-spacer />

        <language-changer :footer_version="true" class="pr-5" />
        <base-btn
          color="accent"
          class="mr-0"
          square
          title="Go to top"
          @click="$vuetify.goTo(0)"
        >
          <v-icon class="up">mdi-chevron-up</v-icon>
        </base-btn>
      </v-row>

      <v-row class="copyright"
        >© {{ new Date().getFullYear() }} Strataggem. {{ $t("global.copyright") }}
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import LanguageChanger from "../app_specific/LanguageChanger.vue";
export default {
  components: { LanguageChanger },
  name: "CoreFooter",

  data: () => ({
    items: [
      {
        href: "http://twitter.com/strataggem",
        icon: "mdi-twitter",
      },
      {
        href: "http://facebook.com/strataggem",
        icon: "mdi-facebook",
      },
    ],
  }),
};
</script>

<style scoped>
.copyright {
  color: #7b796f;
  font-size: small;
  padding: 12px 0 0 12px;
}
.v-btn,
.v-btn.accent {
  color: #15191a;
}
</style>